<template>
  <div class="home-overlay"></div>
</template>

<style>
.home-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: rgba(0,0,0,0.3);
}
</style>